:root {
	--dark-blue: #002222;
	--dark-grey: #3a3a3a;
	--light-blue: #D2F0F9;
	--almost-white: #eee;
	--green: #008000;
	--yellow: #FFD700;
	--red: #ff0000;
	--grey: #808080;
}

body, input, textarea {
	font-family: 'Courier New', Courier, monospace;
}

.app-container {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	grid-template-rows: 1fr auto;
}

.page-container {
	display: grid;
	grid-column: 2/3;
	grid-template-columns: 1fr 2fr;
	column-gap: 100px;
	row-gap: 10px;
}

.page {
	grid-row-start: 2;
	grid-column: 2;
	margin-top: 20px;
}

.entry-list {
	grid-column: 1/1;
	grid-row: 2;
}

.entry-editor {
	grid-column: 2/2;
	grid-row: 2;
}

.entry-editor-form {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto 1fr;
	column-gap: 10px;
	row-gap: 10px;
}

.header {
	grid-column: 2 / 4;
	grid-row: 1 / 1;
	margin-top: 10px;
	margin-bottom: 50px;
}

html, form, col, #root{
	background: var(--dark-blue);
	color: var(--almost-white);
}

input, textarea{
	background-color: var(--almost-white) !important;
}

.bg-light-blue{
	background-color: var(--light-blue);
}

.fs-7{
	font-size: small;
}

.fs-8{
	font-size: x-small;
}

.entry-card-header {
	font-weight: bold;
	color: var(--yellow);
	margin-top: 15pt;
	margin-bottom: 2pt;
	font-size: x-small;
}

.entry-card-content {
	color: var(--almost-white);
	font-size: small;
}

.stretch {
	width: 100%;
}

.mood-icon {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 5px;
}

.content-textarea{
	width: 100%;
	min-height: 500px
}
.mood-0 {
	background: var(--grey);
}

.mood-1 {
	background: var(--red);
}

.mood-2 {
	background: var(--yellow);
}

.mood-3 {
	background: var(--green);
}

.flex {
	display: flex;
}

#header-logo {
	height: 20px;
	fill: var(--almost-white);
	stroke: var(--almost-white);
}